.back-route-button {
  margin-top: 20px;
  padding: 0 5px 0 0;
  display: flex;
  align-items: center;
  gap: 2px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
}

.back-route-button--title {
  text-align: center;
  font-family: MontserratLight;
}
.back-route-button * {
  pointer-events: none;
}