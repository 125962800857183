.ant-collapse .ant-collapse-content {
 padding-left: 0px;
}
.ant-collapse-large
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box {
  padding: 0;
}
.routpage__title__container{
padding-left: 10px;
display: flex;
flex-direction: raw;
gap: 10px;
}

.routepage__link-container{
display: flex;
flex-direction: column;
}

.routpage__link__title {
  white-space: normal;
  text-align: start;
  line-height: 0.9;
}