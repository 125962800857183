.layout__dashlinks-container {
  display: flex;
  padding: 3px 0px 15px 0px;
  justify-content: space-around;
  border-top: 1px solid #16141193;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 100%;
}
.layout__dashlink {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  outline: none;
  cursor: pointer;
  padding-top: 5px;
  color: #78869b;
}
.layout__dashlink--checked {
  color: #9dacc3;
}

.layout__dashlink__title {
  margin-top: 3px;
  font-family: "MontserratLight";
  font-size: 8px;
  text-align: center;
}

.layout__dashlink-icon {
  width: 22px;
  height: 22px;
  fill: #444e5d;
}
.layout__dashlink-icon--checked {
  fill: #9dacc3;
}
