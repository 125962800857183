.counteragent-info-container {
text-align: left

}

.link__title__container--counteragent-modal {
        display: flex;
        flex-direction: raw;
}
.link__title--counteragent-modal {
    font-size: 10px;
    white-space: normal;
    text-align: start;
    line-height: 0.9;
}