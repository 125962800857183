.popup--invoice {
  display: flex;
  flex-direction: column;
  width: 1150px;
  background: #ffffff;
}

.added-products-list{
width: 100%;
}

.invoice {
  margin-top: 20px;
  border-radius: 4px;
  padding: 20px;
  padding-top: 20px;
  /* box-shadow: 3px 3px 6px 0px #ccdbe8 inset,
    -3px -3px 6px 1px rgba(255, 255, 255, 0.5) inset; */
}

.invoice__title {
  font-family: "Roboto-Bold";
  font-size: 25px;
  padding-left: 10px;
  width: 70%;
}

.invoice__header {
  padding-bottom: 10px;
  display: flex;
  gap: 150px;
  border-bottom: solid 2px black;
  align-items: baseline;
}

.layout__logo--invoice {
  width: 74px;
  height: 74px;
}

.invoice__semi-header {
  font-family: "Roboto-Bold";
  line-height: 1.7rem;
  text-align: left;
  font-size: 19px;
  margin-top: 5px;
  padding-left: 10px;
}
.invoice__light-header {
  margin-top: 10px;
  padding-left: 10px;
  font-size: 19px;
}

.total-container--invoice {
  margin-left: 650px;
  display: flex;
  justify-content: space-between;
  width: 400px;
  align-items: baseline;
  padding: 7px;
  padding-right: 50px;
}
.invoice__info-line {
  margin-top: 15px;
  margin-left: 30px;
  display: flex;
}
.sign-line {
  margin-left: 20px;
  margin-top: 30px;
  font-size: 12px;
  width: 300px;
  display: flex;
  justify-content: center;
  border-top: solid 1px black;
}
.total__header--print {
  font-family: "Roboto";
  font-size: 20px;
  margin-right: 10px;
  flex-wrap: nowrap;
}
.total__info--print {
  font-family: Roboto-Bold;
  font-size: 22px;
}

.product-char--print {
  border: 2px solid rgba(0, 0, 0, 0.534);
  border-top: 0px;
  font-size: 17px;
  min-width: 200px;
  text-align: center;
  flex-wrap: nowrap;
}

.product-char__title--print {
  border: 3px solid rgba(0, 0, 0, 0.884);
  border-radius: 4px;
}
