


.common-container .ant-table-thead>tr>th {
    padding: 5px 5px;
    text-align: center;
    font-size: 10px;
}

.common-container .ant-table-tbody>tr>td {
    padding: 2px;
    text-align: center;
}
.button--balance-modal {
padding: 0px
}
.custom-collapse-header {
    font-size: 9px;
}

.custom-modal-content{
display: flex;
flex-direction: column;
text-align: left;
}