.layout__container {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 100%;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
  background-color: #1c232f;
}
.layout__children {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding-top: 80px;
  padding-bottom: 100px;
}
.layout__for_login {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 100px;
}
.layout__header {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background-color: #222a38;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 100;
  height: 45px;
  padding-bottom: 10px;
  max-width: 700px;
}

.layout__logo-container {
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  text-align: center;
  align-items: center;
}

.layout__logo {
  fill: #e1dfdb;
  width: 80px;
  height: 40px;
  margin: 10px 0px 2px 10px;
}

.layout__footer-container {
  position: fixed;
  bottom: 0px;
  max-width: 700px;
  width: 100%;
  background-color: #222a38;
}

.layout__avatar-icon {
  height: 27px;
  width: 27px;
  opacity: 70%;
}

.layout__username {
  font-family: "MontserratMedium";
  margin-top: 2px;
  font-size: 9px;
}

.layout__userinfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fcfdff;
  margin: 10px 20px 2px 0px;
}
