.action-title {
  font-family: "MontserratLight";
  font-size: 10px;
  padding-left: 15px;
  margin-bottom: 5px;
  max-width: 95%;
}

@media (max-width: 375px) {
.action-title {
    font-size: 10px;
  }
}