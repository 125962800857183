.modal__info-container{
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    text-align: center;
    align-content: center;
}

.modal__info-content {
    font-size: 9px;
    font-family: "MontserratLight";
    text-align: center;
}


.modal__info-title {
    font-size: 13px;
    font-family: "MontserratLight";
    text-align: left;
    /* text-align: center; */
}