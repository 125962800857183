body,
html {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoing: grayscale;
}

a {
  text-decoration: none;
}

.column-content-font,
.common-title--large,
.column-title-font,
.table-title-font,
.common-info-title {
  font-family: Arial, sans-serif;
}

.fonts-loaded .column-content-font,
.fonts-loaded .common-title--large {
  font-family: 'MontserratMedium', Arial, sans-serif;
}

.fonts-loaded .column-title-font,
.fonts-loaded .table-title-font,
.fonts-loaded .option-title-font {
  font-family: 'MontserratLight', Arial, sans-serif;
}

.fonts-loaded .common-info-title {
  font-family: 'MontserratBold', Arial, sans-serif;
}


*,
*::before,
*::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}
input:focus,
textarea:focus {
  outline: none;
}

.title-png-icon{
  width: 15px;
  height: 13px;
      filter: invert(100%) sepia(100%) saturate(1000%) hue-rotate(90deg) brightness(100%) contrast(100%);
      margin-top: 4px;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #222a38;
  font-family: "MontserratMedium";
  color: #fcfdff;
}

.common-title--large {
  padding-left: 20px;
  padding-top: 50px;
  margin-bottom: 30px;
}

.column-content-font {
  font-size: 8px;
  font-family: "MontserratMedium";
}


.column-title-font {
  font-size: 9px;
  font-family: "MontserratLight";
  text-align: left;
  margin-right: 7px;
}
.table-title-font {
  font-size: 10px;
  font-family: "MontserratLight";
  text-align: left;
}

.ant-table-footer {
  display: flex;
  flex-direction: row;
}

.order-instance-container {
  display: flex;
  text-align: center;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.ant-table-thead{
  padding: 0px;
}
.common-info-title {
  font-size: 11px;
  font-family: "MontserratBold";
  text-align: left;
  margin-right: 7px;
}
.option-title-font {
  font-size: 9px;
  font-family: "MontserratLight";
  white-space: pre-wrap;
  text-align: left;
}

.order-modal-content{
  padding: 30px, 0px;
  background-color: #fff;
}
.common-modal-title{
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 14px;
}
.common-table-button{
  padding: 0;
  margin: 0;
  width: auto
}


.common-container {
  display: block;
  padding-left: 10px;
  padding-right: 10px;
}
.common-modal__info-block{
margin-top: 16px;
display: flex;
flex-direction: column;
}


.paid {
  background-color: rgba(56, 147, 56, 0.548);
}

.unpaid {
  background-color: #8B0000;
    box-shadow: 0 0 1px 1x rgba(139, 0, 0, 0.5);

    transition: all 0.3s ease;
}

.cancelled {
  background-color: rgba(226, 156, 156, 0.426);

}
.income {
  background-color: rgba(144, 238, 144, 0.32)
}

.outcome {
  background-color: rgba(74, 111, 222, 0.464)
}



.complete {
  background-color: #D4AF37;
  /* deeper gold color */
  box-shadow: 0 0 1px 1px rgba(212, 175, 55, 0.6);
  /* golden glow */
  transition: all 0.3s ease;
  /* smooth transition for hover effects, optional */
}


.fade-enter {
  opacity: 0;
}



.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.mySelectDropdown .ant-select-item-option-content {
  white-space: normal;
  flex-wrap: wrap;
}

@media (max-width: 375px) {
  .common-container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: "Roboto";
  src: url("src/assets/fonts/Roboto/Roboto-Light.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("src/assets/fonts/Roboto/Roboto-Bold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "MontserratLight";
  src: url("src/assets/fonts/Montserrat/MontserratLight.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "MontserratBold";
  src: url("src/assets/fonts/Montserrat/MontserratBold.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "MontserratMedium";
  src: url("src/assets/fonts/Montserrat/MontserratMedium.woff2") format("woff2");
  font-display: swap;
}

